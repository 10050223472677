import React from 'react'

import './styles.scss';

const Punchline = () => {

	return (
		<h2
			className="Punchline"
			data-scroll
		>
			<span>
				Creating little changes for a better world.
				<span className="note"> TT </span>
			</span>
			<span>
				Creating little changes for a better world.
				<span className="note"> TT </span>
			</span>
		</h2>
	)
}

export default Punchline
