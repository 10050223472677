import React, { useEffect, useRef, useState } from 'react';
import { TweenMax, Power2 } from 'gsap';
import LocalImage from '../localImage';
import './styles.scss';
import ExplosionGif from '../../images/Boom.gif';

const Smiley = () => {

	const explosionEl = useRef(null);
	const smileyWrapperEl = useRef(null);

	const handleClick = () => {
		explosionEl.current.style.display = 'block';
		explosionEl.current.src = ExplosionGif;
		smileyWrapperEl.current.style.opacity = 0;
		smileyWrapperEl.current.style.display = 'none';
		setTimeout(() => {
			explosionEl.current.style.display = 'none';
			explosionEl.current.src = null;
		}, 850);
		setTimeout(() => {
			smileyWrapperEl.current.style.display = 'block';
			TweenMax.to(smileyWrapperEl.current, 1, {
				opacity: 1,
				ease: Power2.easeOut
			})
		}, 1500);
	}

	return (
		<>
			<div
				className="Smiley"
				data-scroll
				onClick={handleClick}
				data-cursor-scale
			>
				<div
					className="Smiley__Magnet"
					data-magnet-hover
					data-magnet-hover-offset-min="0.8"
					data-magnet-hover-offset-max="1.2"
					data-magnet-hover-intensity="0.8"
				>
					<div ref={smileyWrapperEl}>
						<LocalImage filename="Smiley.png" />
					</div>
					<img ref={explosionEl} className="Smiley__Explosion" src={null} />
				</div>
			</div>
		</>
	)

}

export default Smiley;