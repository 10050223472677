import { TweenMax, Power2, Elastic } from 'gsap';

let hoverBools = [];
let offsetHoverMins = [];
let offsetHoverMaxs = [];
let offsetHoverIntensities = [];
let els = [];

const magnetHover = () => {
	els = [...document.querySelectorAll('[data-magnet-hover]')];
	for (let i = 0; i < els.length; i += 1) {
		hoverBools[i] = false;
		offsetHoverMins[i] = els[i].getAttribute('data-magnet-hover-offset-min');
		offsetHoverMaxs[i] = els[i].getAttribute('data-magnet-hover-offset-max');
		offsetHoverIntensities[i] = els[i].getAttribute('data-magnet-hover-intensity');
	}
	window.addEventListener('mousemove', handleMouseMove);
}

const handleMouseMove = e => {

	els.map((el, i) => {

		let hoverArea = hoverBools[i] ? offsetHoverMaxs[i] : offsetHoverMins[i];

		// cursor
		// In case we are not using a virtual scroll, y will probably need to take into account the scroll
		const cursor = {
			x: e.clientX,
			y: e.clientY
		};

		// size
		const elRect = el.getBoundingClientRect();
		const width = elRect.width;
		const height = elRect.height;

		// position
		const elPos = {
			x: elRect.left + width / 2,
			y: elRect.top + height / 2
		};

		// comparaison
		const x = cursor.x - elPos.x;
		const y = cursor.y - elPos.y;

		// dist
		const dist = Math.sqrt(x * x + y * y);

		// mutex hover
		let mutHover = false;

		// anim
		if (dist < width * hoverArea) {
			mutHover = true;
			if (!hoverBools[i]) {
				hoverBools[i] = true;
			}
			onHover(el, i, x, y);
		}

		// reset
		if (!mutHover && hoverBools[i]) {
			onLeave(el);
			hoverBools[i] = false;
		}

	})
}

const onHover = (el, i, x, y) => {
	TweenMax.to(el, 1, {
		x: x * offsetHoverIntensities[i],
		y: y * offsetHoverIntensities[i],
		scale: .95,
		rotation: x * 0.025,
		ease: Power2.easeOut
	});
};

const onLeave = (el) => {
	TweenMax.to(el, 1.4, {
		x: 0,
		y: 0,
		scale: 1,
		rotation: 0,
		ease: Elastic.easeOut.config(1.2, 0.4)
	});
};

export default magnetHover;
// From: https://codepen.io/Sirop/pen/jZaQdV