import React from "react"

import './styles.scss'

const PageTitle = ({ top, bottom, topLineOffset, bottomLineOffset }) => {

	return (
		<h1
			className="PageTitle"
			data-scroll
			data-scroll-speed="1.5"
			>
			<span
				className="PageTitle__Line"
				style={{paddingLeft: topLineOffset + '%'}}
				data-scroll
				data-scroll-speed="1"
			>
				<span className="PageTitle__Appear"> {top} </span>
			</span>
			<span
				className="PageTitle__Line"
				style={{paddingLeft: bottomLineOffset + '%'}}
			>
				<span className="PageTitle__Appear"> {bottom} </span>
			</span>
		</h1>
	)
}
export default PageTitle;