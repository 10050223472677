import React, { useEffect, useState, useRef } from "react"
import Img from 'gatsby-image';

import './styles.scss'

const PageImage = ({ image, imageSharp, imageHover, imageHoverSharp }) => {

	const [secondImgVisible, setSecondImgVisible] = useState(false);
	const containerEl = useRef(null);

	let scrollBp = 0;

	useEffect(() => {
		window.addEventListener('customScrollEvent', handleScroll)
		window.addEventListener('scroll', handleScroll)
		window.addEventListener('resize', handleResize);
		handleResize();
		return function clean() {
			window.removeEventListener('customScrollEvent', handleScroll);
			window.removeEventListener('scroll', handleScroll);
			window.removeEventListener('resize', handleResize);
		}
	});

	const handleScroll = e => {
		const scrollY = e.detail ? e.detail.y : window.scrollY;
		if (secondImgVisible === false && scrollY > scrollBp) {
			setSecondImgVisible(true);
			containerEl.current.classList.add('second-img-visible');
		} else if (secondImgVisible === true && scrollY < scrollBp) {
			setSecondImgVisible(false);
			containerEl.current.classList.remove('second-img-visible');
		}
	}

	const handleResize = () => {
		scrollBp = window.innerHeight * 0.2;
	}

	return (
		<div className="PageImage" data-scroll ref={containerEl}>
			<div className="PageImage__ImageContainer">
				<Img
					alt={image.alt}
					fluid={imageSharp.childImageSharp.fluid}
				/>
			</div>
			<div className="PageImage__ImageContainer">
				<Img
					alt={imageHover.alt}
					fluid={imageHoverSharp.childImageSharp.fluid}
				/>
			</div>
		</div>
	)
}
export default PageImage;